<template>
  <loading
    :active="true"
    :is-full-page="false"
    :color="color"
    :height="height"
    :width="width"
    :loader="loader"
    :background-color="backgroundColor"
    :transition="transition"
  />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "PageLoading",
  components: {
    Loading,
  },
  props: {
    loader: {
      type: String,
      default: "spinner",
      validator: (value) => {
        return ["dots", "bars", "spinner"].includes(value);
      },
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    backgroundColor: {
      type: String,
      default: "#11ffee00",
    },
    transition: {
      type: String,
      default: "fade",
    },
    color: {
      type: String,
      default: "#A3A3A3",
    },
  },
};
</script>
